import { Button, Card, CardActions, CardContent, CircularProgress, Container, Grid, TextField, Typography } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import Select from 'react-select';
import { compose } from 'redux';
import FileUploader from 'react-firebase-file-uploader';
import firebase from 'firebase/app';
import 'firebase/storage';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { addNewCatch } from '../../store/actions/boardActions';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker,
  } from '@material-ui/pickers';

class AddCatch extends Component {
    state = {
        angler: null,
        isUploading: false,
        progress: 0,
        photoUrl: '',
        boatOrBank: '',
        length: 0,
        species: '',
        createdTime: Date.now()
    }

    handleUploadStart = () => {
        this.setState({
            isUploading: true,
            progress: 0
        })
    }

    handleProgress = progress => this.setState({ progress });

    handleUploadError = error => {
        this.setState({
            isUploading: false,
        })
    }

    handleUploadSuccess = filename => {
        this.setState({
            progress: 100,
            isUploading: false,
        })
        firebase
        .storage()
        .ref('catches')
        .child(filename)
        .getDownloadURL()
        .then(url =>
        this.setState({
            photoUrl: url
            })
        )
    }
    
    handleSelect = (e) => {
        console.log(e.value)
        this.setState({
            angler: e.value
        })
    }

    handleRadioChange = (e, target) => {
        this.setState({
            [target]: e.target.value
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
        console.log(this.state)
    }

    handleSubmit = async () => {
        await this.props.addNewCatch(this.state)
        this.props.history.push('/admin/')
    }
    handleCancel = () => {
        this.props.history.push('/admin/')
    }
    handleDateChange = (d) => {
        this.setState({
            createdTime: d
        })
    }

    render() {
        const { users } = this.props
        let userOptions = [];
        if (users) {
            users.map((user) => {
                const userOption = {
                    value: user,
                    label: `${user.anglerNumber} - ${user.firstName} ${user.lastName}`
                };
                userOptions.push(userOption);
            })
        }
        return (
            <div>
                <Container style={{"marginTop": 20}}>
                    <Card>
                        <CardContent>
                        <Typography variant="h5">Add New Catch</Typography>
                    <br/>
                        <Typography>Select Angler:</Typography>
                    <Select options={userOptions} onChange={this.handleSelect}/>
                    <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography>Add Photo:</Typography>
                            <Button component="label" variant="contained">
                                <FileUploader
                                style={{ display: 'none' }}
                                accept="image/*"
                                name="photoUrl"
                                storageRef={firebase.storage().ref('catches')}
                                onUploadStart={this.handleUploadStart}
                                onUploadError={this.handleUploadError}
                                onUploadSuccess={this.handleUploadSuccess}
                                onProgress={this.handleProgress}
                                />
                                {this.state.photoUrl === '' ? 'Select Photo' : 'Change Photo'}
                            </Button>
                            { this.state.isUploading ? <CircularProgress/> : null}
                            <br/>
                            <br/>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Caught From</FormLabel>
                                <RadioGroup name="boatOrBank" value={this.state.boatOrBank} onChange={(e, target) => this.handleRadioChange(e, 'boatOrBank')}>
                                    <FormControlLabel value="Boat" control={<Radio/>} label="Boat" />
                                    <FormControlLabel value="Bank" control={<Radio/>} label="Bank" />
                                </RadioGroup>
                            </FormControl>
                            <br/>
                            <TextField id="length" label="Length (cm)" type="number" variant="outlined" fullWidth onChange={this.handleChange}/>
                            <br/>
                            <br/>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Species</FormLabel>
                                <RadioGroup name="species" value={this.state.species} onChange={(e, target) => this.handleRadioChange(e, 'species')}>
                                    <FormControlLabel value="Carp" control={<Radio/>} label="Carp" />
                                    <FormControlLabel value="Baber" control={<Radio/>} label="Baber" />
                                </RadioGroup>
                            </FormControl>
                            <br/>
                            <br/>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DateTimePicker
                                    fullWidth
                                    label="Date/Time"
                                    inputVariant="outlined"
                                    value={this.state.createdTime}
                                    onChange={this.handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <Card>
                                <CardContent>
                                    {
                                        this.state.photoUrl === ''
                                        ?
                                        <Typography>No Photo Uploaded</Typography>
                                        :
                                        <img src={this.state.photoUrl} width="100%" />
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                        </CardContent>
                        <CardActions className="right">
                            <Button onClick={this.handleCancel} color="secondary">Cancel</Button>
                            {
                                this.state.angler !== null && this.state.photoUrl !== '' && this.state.boatOrBank !== '' && this.state.length > 0 && this.state.species !== '' 
                                ?
                                <Button onClick={this.handleSubmit} color="primary">Confirm</Button>
                                :
                                <Button disabled>Confirm</Button>
                            }
                        </CardActions>
                    </Card>
                    
                    
                    
                    
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.firestore.ordered.users
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNewCatch: (newCatch) => dispatch(addNewCatch(newCatch))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'users'}
    ])
)(AddCatch)
