import firebase from '../../config/fbConfig';

const query = firebase.firestore().collection('speciesLeaderboard').orderBy('totalScore', 'desc');

const catchArray = [];
query.onSnapshot((snap) => {
    
    snap.forEach((doc) => {
        catchArray.push(doc.data());
    })
    initState.catchArray = catchArray
})

const initState = {
    
}

const boardReducer = (state = initState, action) => {
    switch (action.type) {
        case 'APPROVED_ENTRY': 
            console.log('Entry Approved!')
            return state;
        case 'APPROVED_ENTRY_ERROR':
            console.log('Entry Approval Error')
            return state;
        case 'DECLINED_ENTRY': 
            console.log('Entry Declined!')
            return state;
        case 'DECLINED_ENTRY_ERROR':
            console.log('Entry Declined Error')
            return state;
        case 'PENDING_DOC_DELETE':
            console.log('Pending Entry Removed')
            return state;
        case 'PENDING_DOC_DELETE':
            console.log('Pending Entry Remove Error')
            return state;
        case 'ADDED_ANGLER':
            console.log('Added angler number')
            return state;
        case 'ADDED_ANGLER_ERROR':
            console.log('Error adding angler number')
            return state;
        case 'CHANGED_VALUE':
            console.log('Changed value')
            return state;
        case 'CHANGED_VALUE_ERROR':
            console.log('Error changing value')
            return state;
        case 'UDPATED_SCORE':
            console.log('Changed value')
            return state;
        case 'UDPATED_SCORE_ERROR':
            console.log('Error changing value', action.err)
            return state;
        case 'NEWCATCH_SUCCESS':
            console.log('Added Catch', action.doc.id)
            return state;
        case 'NEWCATCH_ERROR':
            console.log('Error adding catch', action.err)
            return state;
        case 'EDITED_ANGLER':
            console.log('Edited Angler')
            return state;
        case 'EDIT_ANGLER_FAIL':
            console.log('Error editing angler', action.err)
            return state;
        case 'PW_SUBMIT':
            console.log('Angler Pw Reset')
            return state;
        case 'PW_ERROR':
            console.log('Error resetting angler pw', action.error)
            return state;
        default:
            return state
    }
}

export default boardReducer
