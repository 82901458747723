import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBodttwomphWk5VWtzRKV3Mb7-MDHypqKw",
    authDomain: "foranglers-1.firebaseapp.com",
    databaseURL: "https://foranglers-1.firebaseio.com",
    projectId: "foranglers-1",
    storageBucket: "foranglers-1.appspot.com",
    messagingSenderId: "325147628449",
    appId: "1:325147628449:web:5b91ff200669b551a471e0",
    measurementId: "G-J09LK1QMYL"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export default firebase;