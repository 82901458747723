import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import Grid from '@material-ui/core/Grid';
import { MainBoardEntry } from './MainBoardEntry';
import logo from '../../img/For-Anglers-Badge-Only-01 (1).svg';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class MainLeaderboard extends Component {
  state = {
    mainSelected: true,
    selectedView: 'main',
    selectedPhase: 'all',
  };

  changeView = (selection) => {
    if (selection === 'main') {
      this.setState({
        selectedView: 'main',
      });
    } else if (selection === 'baber') {
      this.setState({
        selectedView: 'baber',
      });
    } else if (selection === 'carp') {
      this.setState({
        selectedView: 'carp',
      });
    }
  };

  changePhase = (phase) => {
    if (phase === 'all') {
      this.setState({
        selectedPhase: 'all',
      });
    } else if (phase === '1') {
      this.setState({
        selectedPhase: '1',
      });
    } else if (phase === '2') {
      this.setState({
        selectedPhase: '2',
      });
    } else if (phase === '3') {
      this.setState({
        selectedPhase: '3',
      });
    }
  };

  render() {
    const { speciesEntries, baberBoard, carpBoard } = this.props;
    let entryPosition = 0;
    var results;
    if (this.state.selectedView === 'main') {
      if (this.state.selectedPhase === 'all') {
        results =
          speciesEntries &&
          speciesEntries.map((entry) => {
            entryPosition++;
            return (
              <MainBoardEntry
                entry={entry}
                key={entryPosition}
                position={entryPosition}
              />
            );
          });
      } else {
        const filteredResults = speciesEntries.filter(
          (item) => item.phase === this.state.selectedPhase
        );

        results =
          filteredResults &&
          filteredResults.map((entry) => {
            entryPosition++;
            return (
              <MainBoardEntry
                entry={entry}
                key={entryPosition}
                position={entryPosition}
              />
            );
          });
      }
    } else if (this.state.selectedView === 'baber') {
      if (this.state.selectedPhase === 'all') {
        results =
          baberBoard &&
          baberBoard.map((entry) => {
            entryPosition++;
            return (
              <MainBoardEntry
                entry={entry}
                key={entryPosition}
                position={entryPosition}
              />
            );
          });
      } else {
        const filteredBarberResults = baberBoard.filter(
          (item) => item.phase === this.state.selectedPhase
        );
        results =
          filteredBarberResults &&
          filteredBarberResults.map((entry) => {
            entryPosition++;
            return (
              <MainBoardEntry
                entry={entry}
                key={entryPosition}
                position={entryPosition}
              />
            );
          });
      }
    } else if (this.state.selectedView === 'carp') {
      if (this.state.selectedPhase === 'all') {
        results =
          carpBoard &&
          carpBoard.map((entry) => {
            entryPosition++;
            return (
              <MainBoardEntry
                entry={entry}
                key={entryPosition}
                position={entryPosition}
              />
            );
          });
      } else {
        const filteredCarpResults = carpBoard.filter(
          (item) => item.phase === this.state.selectedPhase
        );
        results =
          filteredCarpResults &&
          filteredCarpResults.map((entry) => {
            entryPosition++;
            return (
              <MainBoardEntry
                entry={entry}
                key={entryPosition}
                position={entryPosition}
              />
            );
          });
      }
    }
    return (
      <div className="container">
        <Grid
          style={{ marginBottom: 20 }}
          container
          justify="space-between"
          alignItems="center"
          spacing={0}
        >
          <img src={logo} width="100" />
          <h3>Mitchell Berkley Bonanza 2020+</h3>
        </Grid>
        <Grid style={{ marginBottom: 20 }} container alignContent="center">
          <Button
            variant="contained"
            color={this.state.selectedView === 'main' ? 'default' : 'primary'}
            onClick={(species) => this.changeView('main')}
          >
            Main Leaderboard
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color={this.state.selectedView === 'baber' ? 'default' : 'primary'}
            onClick={(species) => this.changeView('baber')}
          >
            Baber Leaderboard
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color={this.state.selectedView === 'carp' ? 'default' : 'primary'}
            onClick={(species) => this.changeView('carp')}
          >
            Carp Leaderboard
          </Button>
        </Grid>
        <Grid style={{ marginBottom: 20 }} container alignContent="center">
          <Button
            variant="contained"
            color={this.state.selectedPhase === 'all' ? 'default' : 'primary'}
            onClick={() => {
              this.changePhase('all');
            }}
          >
            All
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color={this.state.selectedPhase === '1' ? 'default' : 'primary'}
            onClick={() => {
              this.changePhase('1');
            }}
          >
            Bonanza Month 1
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color={this.state.selectedPhase === '2' ? 'default' : 'primary'}
            onClick={() => {
              this.changePhase('2');
            }}
          >
            Bonanza Month 2
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color={this.state.selectedPhase === '3' ? 'default' : 'primary'}
            onClick={() => {
              this.changePhase('3');
            }}
          >
            Bonanza Month 3
          </Button>
        </Grid>
        {results}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // For all the logs..
  const allLogs = state.firestore.ordered.catchLogTest;

  if (typeof allLogs === 'object') {
    var allLogArray = [];

    for (let i = 0; i < allLogs.length; i++) {
      const item = allLogs[i];
      console.log(item.phase);
      item.time = item.createdTime.seconds;
      allLogArray.push(item);
    }

    var finalAllLogArray = _.orderBy(
      allLogArray,
      [
        function (item) {
          return item.score;
        },
        function (item) {
          return item.time;
        },
      ],
      ['asc', 'desc']
    );

    finalAllLogArray = finalAllLogArray.reverse();
  }

  // For the baber logs.

  const barberLogs = state.firestore.ordered.baberBoard;

  if (typeof barberLogs === 'object') {
    var barberLogArray = [];

    for (let i = 0; i < barberLogs.length; i++) {
      const item = barberLogs[i];
      item.time = item.createdTime.seconds;
      barberLogArray.push(item);
    }

    var finalBarberLogs = _.orderBy(
      barberLogArray,
      [
        function (item) {
          return item.score;
        },
        function (item) {
          return item.time;
        },
      ],
      ['asc', 'desc']
    );

    finalBarberLogs = finalBarberLogs.reverse();
  }

  // For the carp logs.

  const carpLogs = state.firestore.ordered.carpBoard;

  if (typeof carpLogs === 'object') {
    var carpLogArray = [];

    for (let i = 0; i < carpLogs.length; i++) {
      const item = carpLogs[i];
      item.time = item.createdTime.seconds;
      carpLogArray.push(item);
    }

    var finalCarpLogs = _.orderBy(
      carpLogArray,
      [
        function (item) {
          return item.score;
        },
        function (item) {
          return item.time;
        },
      ],
      ['asc', 'desc']
    );

    finalCarpLogs = finalCarpLogs.reverse();
  }

  return {
    speciesEntries: finalAllLogArray || [],
    baberBoard: finalBarberLogs || [],
    carpBoard: finalCarpLogs || [],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {
      collection: 'catchLogTest',
      orderBy: ['score', 'desc'],
      where: [
        ['species', '==', 'Baber'],
        ['status', '==', 'approved'],
      ],
      storeAs: 'baberBoard',
    },
    {
      collection: 'catchLogTest',
      orderBy: ['score', 'desc'],
      where: [
        ['species', '==', 'Carp'],
        ['status', '==', 'approved'],
      ],
      storeAs: 'carpBoard',
    },
    {
      collection: 'catchLogTest',
      orderBy: ['score', 'desc'],
      where: ['status', '==', 'approved'],
    },
  ])
)(MainLeaderboard);
