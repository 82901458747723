import React, { Component } from 'react'
import { ButtonGroup, Button, Container, TableHead, TableBody, TableCell, TableRow, Table, Paper} from '@material-ui/core'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { approveEntry, declineEntry } from '../../store/actions/boardActions'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PendingView from './PendingView'
import ApprovedView from './ApprovedView'
import DeclinedView from './DeclinedView'
import { Redirect } from 'react-router-dom'

export class AdminView extends Component {
    state = {
        selectedView: 'pending',
    }
    handleViewChange = async (target) => {
        await this.setState({
            selectedView: target
        })
    }

    handleApprove = (entry, docId) => {
        this.props.approveEntry(entry, docId)
    }
    handleDecline = (entry, docId) => {
        this.props.declineEntry(entry, docId)
    }
    render() {
        const { catchLogTest, approvedLog, declinedLog, auth } = this.props;
        if (!auth.uid) return <Redirect to='/login' /> 
        if (approvedLog){console.log(approvedLog[0].Location.latitude)}
        var renderView = <PendingView />;
        
        if (this.state.selectedView == 'pending') {
            renderView = <PendingView />
        }
        else if (this.state.selectedView === 'approved') {
            renderView = <ApprovedView />
        } else if (this.state.selectedView === 'declined') {
            renderView = <DeclinedView />
        }
        return (
            <div>
                <Container style={{
                    "marginTop": 20,
                    "textAlign": "center"
                }}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                            variant={
                                this.state.selectedView === 'pending' ? "contained" : null
                            }
                            color={
                                this.state.selectedView == 'pending' ? "primary" : null
                            }
                            onClick={
                                (target) => this.handleViewChange('pending')
                            }
                        >Pending</Button>
                        <Button
                            variant={
                                this.state.selectedView == 'approved' ? "contained" : null
                            }
                            color={
                                this.state.selectedView == 'approved' ? "primary" : null
                            }
                            onClick={
                                (target) => this.handleViewChange('approved')
                            }
                        >Approved</Button>
                        
                        <Button
                            variant={
                                this.state.selectedView == 'declined' ? "contained" : null
                            }
                            color={
                                this.state.selectedView == 'declined' ? "primary" : null
                            }
                            onClick={
                                (target) => this.handleViewChange('declined')
                            }
                        >Declined</Button>
                    </ButtonGroup>
                    <Link to='/add-angler'>
                        <Button 
                        style={{
                            "float": "right"
                        }}
                        variant="contained"
                        >Add Anglers</Button>
                    </Link>
                    <Link to='/admin/angler-list/'>
                        <Button 
                        style={{
                            "float": "left"
                        }}
                        color="primary"
                        >Angler list</Button>
                    </Link>
                    <Link to='/admin/add-catch/'>
                        <Button 
                        style={{
                            "float": "left"
                        }}
                        variant="contained"
                        color="primary"
                        >Add Catch</Button>
                    </Link>
                    <Button style={{
                        "float": "right"
                    }}>Log Out</Button>
                </Container>
                <Container style={{
                    "marginTop": 20
                }}>
                    {
                        renderView
                    }
                    
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        catchLogTest: state.firestore.ordered.catchLogTest,
        auth: state.firebase.auth,
        // declinedLog: state.firestore.ordered.declinedLog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        approveEntry: (entry, docId) => dispatch(approveEntry(entry, docId)),
        declineEntry: (entry, docId) => dispatch(declineEntry(entry, docId))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'catchLogTest', orderBy: ['createdTime', 'desc']},
        // { collection: 'approvedLog', orderBy: ['createdTime', 'desc']  },
        // { collection: 'declinedLog', orderBy: ['createdTime', 'desc']  }
    ])
)(AdminView)
