export const signIn = (credentials) => {
    return async (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();

        await firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: 'LOGIN_SUCCESS'})
        }).catch((err) => {
            dispatch({ type: 'LOGIN_ERROR'})
        })
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            dispatch({ type: 'SIGNOUT_SUCCESS' })
        }).catch((err) => {
            dispatch({ type: 'SIGNOUT_ERROR' })
        })
    }
}