import { Container, Paper, Table, TableHead, TableCell, TableRow, TableBody, Button } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { approveEntry } from '../../store/actions/boardActions'

export class PendingView extends Component {
    
    handleApprove = (entry, docId) => {
        this.props.approveEntry(entry, docId)
    }
    render() {
        const { catchLogTest } = this.props
        console.log(catchLogTest);
        return (
            <div>
                <Container style={{"marginTop": 20}}>
                    <Table component={Paper}>
                        <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Angler No.
                                    </TableCell>
                                    <TableCell>
                                        Timestamp
                                    </TableCell>
                                    <TableCell>
                                        Angler Name
                                    </TableCell>
                                    <TableCell>
                                        Species
                                    </TableCell>
                                    {/* <TableCell>
                                        Length
                                    </TableCell> */}
                                    <TableCell>
                                        Score
                                    </TableCell>
                                    <TableCell>
                                        Location
                                    </TableCell>
                                    <TableCell>
                                        Photo
                                    </TableCell>
                                    <TableCell>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    catchLogTest && catchLogTest.map((entry) => {
                                        return (
                                            <TableRow key={entry.id}>
                                    <TableCell>
                                        {entry.anglerNumber}
                                    </TableCell>
                                    <TableCell>
                                        {moment(entry.createdTime.toDate()).format("MMM Do, h:mm a")}
                                    </TableCell>
                                    <TableCell>
                                        {entry.anglerName}
                                    </TableCell>
                                    <TableCell>
                                        {entry.species}
                                    </TableCell>
                                    {/* <TableCell>
                                        {entry.length}
                                    </TableCell> */}
                                    <TableCell>
                                        {entry.score}
                                    </TableCell>
                                    
                                        
                                        <TableCell>
                                            {
                                                entry.location || entry.Location
                                                ?
                                                <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${entry.Location.latitude},${entry.Location.longitude}`}>
                                                    <Button variant="contained" color="primary">
                                                        View Map
                                                    </Button>
                                                </a>
                                                :
                                                'No Location Provided'

                                            }
                                        </TableCell>
                                    
                                    
                                        
                                        <TableCell>
                                            <a target="_blank" href={entry.photoUrl}>
                                                <Button variant="contained" color="primary">
                                                    View Photo
                                                </Button>
                                            </a>
                                        </TableCell>
                                    
                                    
                                        <TableCell>
                                            <Link to={`/edit/${entry.id}`}>
                                                <Button>
                                                    View
                                                </Button>
                                            </Link>
                                            
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={
                                                    (thisEntry, docId) => this.handleApprove(entry, entry.id)
                                                }
                                            >
                                                Approve
                                            </Button>
                                            &nbsp;
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                disabled
                                            >
                                                Decline
                                            </Button>
                                        </TableCell>
                                </TableRow>
                                        )
                                    })
                                }
                                            
                            </TableBody>
                    </Table>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        catchLogTest: state.firestore.ordered.catchLogTest
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        approveEntry: (entry, docId) => dispatch(approveEntry(entry, docId))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        { collection: 'catchLogTest', where: ['status', '==', 'declined'], orderBy: ['createdTime', 'desc']}
    ])
)(PendingView)