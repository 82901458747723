import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainLeaderboard from './components/leaderboard/MainLeaderboard';
import SpeciesLeaderboard from './components/leaderboard/SpeciesLeaderboard';
import Login from './components/admin/Login';
import AdminView from './components/admin/AdminView';
import AddAngler from './components/admin/AddAngler';
import EditView from './components/admin/EditView';
import PendingView from './components/admin/PendingView';
import AddCatch from './components/admin/AddCatch';
import AnglerList from './components/admin/AnglerList';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route exact path='/' component={MainLeaderboard} />
          <Route path='/species-board' component={SpeciesLeaderboard} />
          <Route path='/login' component={Login} />
          <Route exact path='/admin' component={AdminView} />
          <Route path='/add-angler' component={AddAngler} />
          <Route path='/edit/:id' component={EditView} />
          <Route path='/admin/pending' component={PendingView} />
          <Route path='/admin/add-catch' component={AddCatch} />
          <Route path='/admin/angler-list' component={AnglerList} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
