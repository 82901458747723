import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import baber from '../../img/Baber.png';
import carp from '../../img/Common.png';
import modder from '../../img/Moddervis.png';
import userAvatar from '../../img/Portrait_Placeholder.png';
import bronze from '../../img/Bronze.png';
import silver from '../../img/Silver.png';
import gold from '../../img/Gold.png';
import moment from 'moment';

export const MainBoardEntry = ({ entry, position }) => {
  let medal = <div></div>;

  if (position == 1) {
    medal = <img src={gold} width="40" />;
  } else if (position == 2) {
    medal = <img src={silver} width="40" />;
  } else if (position == 3) {
    medal = <img src={bronze} width="40" />;
  } else {
    medal = <div></div>;
  }
  var fishImage;
  if (entry.species == 'Baber') {
    fishImage = baber;
  } else {
    fishImage = carp;
  }
  return (
    <div className="card" key={entry.id}>
      <div className="card-content">
        <Grid container justify="space-between" alignItems="center" spacing={4}>
          <div style={{ padding: '10px 40px' }}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Avatar alt="Remy Sharp" src={userAvatar} />
              <p>&nbsp;</p>
              <p>
                &nbsp;{entry.anglerNumber} - {entry.anglerName} {entry.phase}
              </p>
            </Grid>
          </div>
          <p>
            <b>Score: </b>
            {entry.score}
          </p>

          <div>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <div style={{ padding: '10px' }}>
                <img src={fishImage} width="60" />
                <p className="fishScore">Species: {entry.species}</p>
              </div>
              <div style={{ padding: '10px' }}>
                <p className="fishScore">Length: {entry.length} cm</p>
                {entry.sortingIndicator}{' '}
                <span className="fishScore">
                  {moment(entry.createdTime.toDate()).format('MMM Do, h:mm a')}
                </span>
              </div>
            </Grid>
          </div>

          <div style={{ padding: '10px 40px' }}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              {medal}
              <div style={{ width: '20px' }}></div>
              <Avatar className="blue">{position}</Avatar>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};
