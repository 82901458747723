import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { addAnglerNumber } from '../../store/actions/boardActions'
import { Button, Card, CardActions, CardContent, Container, TextField } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom'

class AddAngler extends Component {
    state = {
        anglerNumber: '',
        name: '',
        surname: '',
        email: '',
        cellNumber: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = () => {
       this.props.addAnglerNumber(this.state)
       this.props.history.push('/admin')
    }
    render() {
        const { auth } = this.props;
        console.log(auth)
        if (!auth.uid) return <Redirect to='/login' /> 
        return (
            <div>
                <Container
                    style={{
                        "marginTop": 40
                    }}
                >
                <Card>
                    <CardContent>
                        <div className="input-field">
                            <TextField 
                                id="anglerNumber"
                                onChange={this.handleChange}
                                label="Tick Number / Angler Number"
                                fullWidth
                            />
                            <TextField 
                                id="name"
                                onChange={this.handleChange}
                                label="Name"
                                fullWidth
                            />
                            <TextField 
                                id="surname"
                                onChange={this.handleChange}
                                label="Surname"
                                fullWidth
                            />
                            <TextField 
                                id="email"
                                onChange={this.handleChange}
                                label="Email"
                                fullWidth
                            />
                            <TextField 
                                id="cellNumber"
                                onChange={this.handleChange}
                                label="Cell Number"
                                fullWidth
                            />
                        </div>
                        <Button
                            onClick={this.handleSubmit}
                            variant="contained"
                            color="primary"
                            disabled={ this.state.anglerNumber !== '' ? false : true }
                        >
                            Submit
                        </Button>
                    </CardContent>
                    <CardActions>
                        <Link to="/admin/"><Button color="primary" startIcon={<KeyboardBackspaceIcon />}>Back</Button></Link>
                    </CardActions>
                </Card>
                
                    
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
    }
  }

const mapDispatchToProps = (dispatch) => {
    return {
        addAnglerNumber: (angler) => dispatch(addAnglerNumber(angler))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(AddAngler)
