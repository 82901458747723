import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Container, Grid, Button } from '@material-ui/core'
import logo from '../../img/For-Anglers-Badge-Only-01 (1).svg';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';

export class Login extends Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Signing In');
        await this.props.signIn(this.state);
        this.props.history.push('/admin')
    }
    render() {
        return (
            <Container>
                <Grid container justify='space-between' alignItems="center" spacing={0}>
                    <img src={logo} width="100"/>
                    <h3>Mitchell Berkley Bonanza 2020+</h3>
                    <h5>Admin Login</h5>
                </Grid>
                <div className="row">
                    <form className="col s12">
                        <div className="input-field">
                            <label htmlFor="email">Email</label>
                            <input 
                                type="email" 
                                id="email" 
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="input-field">
                            <label htmlFor="password">Password</label>
                            <input type="password" id="password" onChange={this.handleChange}/>
                        </div>
                        <div className="input-field">
                            <Button variant="contained" color="primary" onClick={this.handleSubmit}>Sign In</Button>
                        </div>
                    </form>
                </div>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
