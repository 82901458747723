import { ButtonGroup, Container, Button, Table, TableHead, Paper, TableRow, TableCell, TableBody, TextField, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Link, Redirect } from 'react-router-dom'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { editAnglerUser, changePw } from '../../store/actions/boardActions'

class AnglerList extends Component {
    state = {
        selectedView: 'dbList',
        search: '',
        personArray: [],
        dialogOpen: false,
        dialogData: {},
        editData: {},
        pwDialogOpen: false,
        pw: '',
        anglerId: ''
    }
    handleViewChange = (target) => {
        this.setState({
            selectedView: target
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSearch = () => {
        let filteredArray = []
        for (var i=0; i < this.props.anglerNumbers.length; i++) {
            if (this.props.anglerNumbers[i].name === this.state.search) {
                const result = this.props.anglerNumbers[i]
                filteredArray.push(result)
            }
        }
        
    }
    openDialog = async (data) => {
        await this.setState({
            dialogOpen:true,
            dialogData: {...data}
        })
        console.log(this.state.dialogData)
    }
    openPwDialog = (anglerId) => {
        this.setState({
            pwDialogOpen: true,
            anglerId
        })
    }
    closePwDialog = () => {
        this.setState({
            pwDialogOpen: false,
            pw: '',
            anglerId: ''
        })
    }
    pwChange = (e) => {
        this.setState({
            pw: e.target.value
        })
    }
    pwConfirm = async () => {
        await this.props.changePw(this.state.anglerId, this.state.pw);
        this.closePwDialog();
    }
    closeDialog = () => {
        this.setState({
            dialogOpen: false,
            dialogData: {}
        })
    }
    handleEditChange = async (e, docId) => {
        
        await this.setState({
            dialogData: {
                id: docId,
                [e.target.id]: e.target.value
            }
        })
        console.log(this.state.dialogData)
    }
    handleEditConfirm = async () => {
        await this.props.editAnglerUser(this.state.dialogData, this.state.dialogData.id)
        this.closeDialog()
    }
    render() {
        const { users, anglerNumbers, auth } = this.props
        if (!auth.uid) return <Redirect to='/login' /> 
        
        
        return (
            <div>
                <Container style={{"marginTop": 20, "textAlign": "center"}}>
                    <Link style={{"float":"left"}} to="/admin/"><Button color="primary" startIcon={<KeyboardBackspaceIcon />}>Back</Button></Link>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                        <Button
                            variant={
                                this.state.selectedView === 'dbList' ? "contained" : null
                            }
                            color={
                                this.state.selectedView == 'activeList' ? "primary" : null
                            }
                            onClick={
                                (target) => this.handleViewChange('dbList')
                            }
                        >All (Database)</Button>
                        <Button
                            variant={
                                this.state.selectedView === 'registered' ? "contained" : null
                            }
                            color={
                                this.state.selectedView == 'registered' ? "primary" : null
                            }
                            onClick={
                                (target) => this.handleViewChange('registered')
                            }
                        >Registered</Button>
                    </ButtonGroup>
                    <div style={{"marginTop": 20}}>
                        <TextField onChange={this.handleChange} id="search" label="Search Ticket Nr."/> 
                    </div>
                    <Table component={Paper} >
                        <TableHead>
                            <TableRow>
                                <TableCell>Ticket Number</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Cell Number</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {   
                                this.state.selectedView === 'dbList'
                                ?
                                anglerNumbers && anglerNumbers.map((person) => {
                                    if (this.state.search !== '') {
                                        if (person.anglerNumber === this.state.search) {
                                            return (
                                                <TableRow key={person.id}>
                                                    <TableCell>{person.anglerNumber}</TableCell>
                                                    <TableCell>{person.name}</TableCell>
                                                    <TableCell>{person.surname}</TableCell>
                                                    <TableCell>{person.email}</TableCell>
                                                    <TableCell>{person.cellNumber}</TableCell>
                                                    <TableCell><Button color="primary">View</Button></TableCell>
                                                </TableRow>
                                            )
                                        } else {
                                            return null
                                        }
                                    } else {
                                        return (
                                            <TableRow key={person.id}>
                                                <TableCell>{person.anglerNumber}</TableCell>
                                                <TableCell>{person.name}</TableCell>
                                                <TableCell>{person.surname}</TableCell>
                                                <TableCell>{person.email}</TableCell>
                                                <TableCell>{person.cellNumber}</TableCell>
                                                <TableCell><Button color="primary">-</Button></TableCell>
                                            </TableRow>
                                        )
                                    }
                                    
                                })
                                :
                                users && users.map((person) => {
                                    if (this.state.search !== '') {
                                        if(person.anglerNumber === this.state.search) {
                                            return (
                                                <TableRow key={person.id}>
                                                    <TableCell>{person.anglerNumber}</TableCell>
                                                    <TableCell>{person.firstName}</TableCell>
                                                    <TableCell>{person.lastName}</TableCell>
                                                    <TableCell>{person.cellNumber}</TableCell>
                                                    <TableCell>{person.email}</TableCell>
                                                    <TableCell>
                                                        <Button onClick={(data) => this.openDialog(person)} color="primary">Edit</Button>
                                                        <Button onClick={this.openPwDialog} color="primary">Change PW</Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    } else {
                                        return (
                                            <TableRow key={person.id}>
                                                <TableCell>{person.anglerNumber}</TableCell>
                                                <TableCell>{person.firstName}</TableCell>
                                                <TableCell>{person.lastName}</TableCell>
                                                <TableCell>{person.cellNumber}</TableCell>
                                                <TableCell>{person.email}</TableCell>
                                                <TableCell>
                                                    <Button onClick={(data) => this.openDialog(person)} color="primary">Edit</Button>
                                                    <Button onClick={(anglerId) => this.openPwDialog(person.id)} color="primary">Change PW</Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    
                                })
                            }
                        </TableBody>
                        
                    </Table>
                    <Dialog maxWidth="sm" fullWidth open={this.state.dialogOpen} onClose={this.closeDialog}>
                        <DialogContent>
                            <TextField value={this.state.dialogData.anglerNumber} id="anglerNumber" label="Ticket Number" onChange={(e, docId) => this.handleEditChange(e, this.state.dialogData.id)} fullWidth/>
                            <TextField value={this.state.dialogData.firstName} id="firstName" label="First Name" onChange={(e, docId) => this.handleEditChange(e, this.state.dialogData.id)} fullWidth/>
                            <TextField value={this.state.dialogData.lastName} id="lastName" label="Last Name" onChange={(e, docId) => this.handleEditChange(e, this.state.dialogData.id)} fullWidth/>
                            <TextField value={this.state.dialogData.cellNumber} id="cellNumber" label="Cell Number" onChange={(e, docId) => this.handleEditChange(e, this.state.dialogData.id)} fullWidth/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="secondary">Cancel</Button>
                            <Button onClick={this.handleEditConfirm} color="primary">Confirm</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={this.closePwDialog} maxWidth="sm" fullWidth open={this.state.pwDialogOpen}>
                        <DialogContent>
                            <TextField label="New Password" id="pwChange" fullWidth onChange={this.pwChange}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closePwDialog} color="secondary">Cancel</Button>
                            <Button onClick={this.pwConfirm} color="primary">Confirm</Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        anglerNumbers: state.firestore.ordered.anglerNumbers,
        users: state.firestore.ordered.users,
        uid: state.firebase.auth.uid,
        auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editAnglerUser: (angler, id) => dispatch(editAnglerUser(angler,id)),
        changePw: (anglerId, pw) => dispatch(changePw(anglerId, pw))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect([
        {
            collection: 'anglerNumbers'
        },
        {
            collection: 'users'
        }
    ])
)(AnglerList)
