import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Container, Dialog, DialogActions, DialogTitle, Grid, TextField } from '@material-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { updateField, updateScore } from '../../store/actions/boardActions'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from 'react-router-dom'

export class EditView extends Component {
    state = {
        length: '',
        dialogOpen: false,
        field: '',
        tempFieldValue: ''
    }
    editField = (target, value) => {
        console.log(target)
        this.setState({
            dialogOpen: true,
            tempFieldValue: value,
            field: target
        })
    }
    handleChange = (e) => {
        this.setState({
            tempFieldValue: e.target.value
        })
    }
    handleSubmit = async () => {
        await this.props.updateField(
            this.props.id, 
            this.state.field,
            this.state.tempFieldValue
        )
        this.setState({
            field: '',
            tempFieldValue: '',
            dialogOpen: false
        })
    }
    handleCancel = () => {
        this.setState({
            field: '',
            tempFieldValue: '',
            dialogOpen: false
        })
    }
    handleScoreUpdate =  async (uid, oldScore) => {
        console.log(uid, oldScore)
        await this.props.updateScore(
            uid,
            oldScore,
            this.state.tempFieldValue
        )
        this.handleSubmit();
    }
    render() {
        const { entry, id } = this.props;
        console.log(entry)
        if (entry == null) {
            return (
                <Container style={{"marginTop": 20}}>
                    Loading
                </Container>
            )
        }
        else {
            return (
                <div>
                    <Container style={{"marginTop": 20}}>
                        <Card style={{"background": "#f5f9fb"}}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Card>
                                            <CardHeader 
                                                //{entry.cellNumber ? entry.cellNumber : 'No Cell Number'}
                                                avatar={
                                                    entry[0].profilePicUrl ? 
                                                    <Avatar src={entry[0].profilePicUrl} />
                                                    : 
                                                    <Avatar></Avatar>
                                                }
                                                title={entry[0].anglerNumber + ' - ' + entry[0].anglerName}
                                                subheader={entry[0].cellNumber}
                                            />
                                        </Card>
                                        <br/>
                                        <Card>
                                            <CardContent>
                                                Length: {entry[0].length}
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    onClick={(target, value) => this.editField('length',entry[0].length)}
                                                >Edit</Button>
                                            </CardActions>
                                        </Card>
                                        <br/>
                                        {/* Updating Score MUST Affect SpeciesLeaderboard */}
                                        <Card>
                                            <CardContent>
                                                Score: {entry[0].score}
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    onClick={(target, value) => this.editField('score',entry[0].score)}
                                                >Edit</Button>
                                            </CardActions>
                                        </Card>
                                        {/* Updating Score MUST Affect SpeciesLeaderboard */}
                                        <br/>
                                        <Card>
                                            <CardContent>
                                                Species: {entry[0].species}
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    onClick={() => this.editField('species',entry[0].species)}
                                                >Edit</Button>
                                            </CardActions>
                                        </Card>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <img src={entry[0].photoUrl} height="450"/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Link to="/admin/"><Button color="primary" startIcon={<KeyboardBackspaceIcon />}>Back</Button></Link>
                            </CardActions>
                        </Card>
                    </Container>
                    <Dialog
                        open={this.state.dialogOpen}
                    >
                        <DialogTitle>Edit {this.state.field}</DialogTitle>
                        <Container>
                            <TextField 
                                defaultValue={this.state.tempFieldValue}
                                onChange={this.handleChange}
                            />
                        </Container>
                        <DialogActions>
                            {
                                this.state.field === 'score'
                                ? 
                                <Button onClick={(uid, oldScore) => this.handleScoreUpdate(entry[0].anglerId, entry[0].score)} color="primary">Update Score</Button>
                                :
                                <Button onClick={this.handleSubmit} color="primary">Confirm</Button>
                            }
                            
                            <Button onClick={this.handleCancel} color="secondary">Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.id,
        entry: state.firestore.ordered.catchLogTest,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (catchId, field, value) => dispatch(updateField(catchId, field, value)),
        updateScore: (userId, oldValue, newValue) => dispatch(updateScore(userId, oldValue, newValue))
    }
    
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        { collection: 'catchLogTest', doc: props.id } 
    ])
)(EditView)
