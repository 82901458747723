// Here is where we send stuff to database
export const approveEntry = (entry, docId) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();
       
        await firestore.collection('catchLogTest').doc(docId).update({
            status: 'approved'
        }).then(() => {
            dispatch({ type: 'APPROVED_ENTRY' })
        }).catch((err) => {
            dispatch({ type: 'APPROVED_ENTRY_ERROR'})
        })
    }
}

export const declineEntry = (entry, docId) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        await firestore.collection('catchLogTest').doc(docId).update({
            status: 'declined'
        }).then(() => {
            dispatch({ type: 'DECLINED_ENTRY' })
        }).catch((err) => {
            dispatch({ type: 'DECLINED_ENTRY_ERROR'})
        })
    }
}

export const addAnglerNumber = (angler) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        await firestore.collection('anglerNumbers').add({
            ...angler
        }).then(() => {
            dispatch({ type: 'ADDED_ANGLER'})
        }).catch((err) => {
            dispatch({ type: 'ADDED_ANGLER_ERROR'})
        })
    }
}

export const editAnglerUser = (data, uid) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        await firestore.collection('users').doc(uid).update({
            ...data
        }).then((doc) => {
            dispatch({ type: 'EDITED_ANGLER', doc})
        }).catch((err) => {
            dispatch({ type: 'EDIT_ANGLER_FAIL', err})
        })
    }
}

export const updateField = (catchId, field, value) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        if (field == 'length') {
           value = parseInt(value, 10)
        }
        else if (field == 'score') {
            value = parseInt(value, 10)
        }
        await firestore.collection('catchLogTest').doc(catchId).update({
            [field]: value
        }).then(() => {
            dispatch({ type: 'CHANGED_VALUE'})
        }).catch((err) => {
            dispatch({ type: 'CHANGED_VALUE_ERROR'})
        })
    }
}

export const updateScore = (userId, oldValue, newValue) => {
    return async (dispatch, getState, { getFirebase, getFirestore}) => {
        const firestore = getFirestore();
        const catchRef = firestore.collection('speciesLeaderboard').doc(userId);
        const convertedOldValue = parseInt(oldValue, 10);
        const convertedNewValue = parseInt(newValue, 10);
        console.log(convertedNewValue, convertedOldValue)
        await firestore.runTransaction((t) => {
            return t.get(catchRef).then((catchDoc) => {
                if (!catchDoc.exists) {
                    console.log('Error Finding Doc')
                }
                var oldScore = catchDoc.data().totalScore - convertedOldValue;
                var newScore = oldScore + convertedNewValue;
                t.update(catchRef, { totalScore: newScore });
            })
        }).then(() => {
            dispatch({ type: 'UDPATED_SCORE'})
        }).catch((err) => {
            dispatch({ type: 'UDPATED_SCORE_ERROR', err})
        })
    }
}

export const addNewCatch = (newCatch) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        
        let lengthFloat = parseFloat(newCatch.length)
        let convertedScore = 0;
        if (newCatch.species === 'Carp') {
            convertedScore = newCatch.length * 2.5
        }
        else if (newCatch.species === 'Baber'){
            convertedScore = newCatch.length * 1
        }

        firestore.collection('catchLogTest').add({
            anglerId: newCatch.angler.id,
            anglerName: `${newCatch.angler.firstName} ${newCatch.angler.lastName}`,
            anglerNumber: newCatch.angler.anglerNumber,
            cellNumber: newCatch.angler.cellNumber ? newCatch.angler.cellNumber : 'No Cell Number',
            createdTime: newCatch.createdTime,
            length: lengthFloat,
            marshallId: newCatch.angler.id,
            photoUrl: newCatch.photoUrl,
            score: convertedScore,
            species: newCatch.species,
            status: "pending",
            phase: "2"
        }).then((doc) => {
            dispatch({ type: 'NEWCATCH_SUCCESS', doc })
        }).catch((err) => {
            dispatch({ type: 'NEWCATCH_ERROR', err })
        })
    }
}

export const changePw = (anglerId, pw) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firestore = getFirestore();

        firestore.collection('pwChanges').add({
            anglerId,
            pw
        }).then(() => {
            dispatch({ type: 'PW_SUBMIT'})
        }).catch((error) => {
            dispatch({ type: 'PW_ERROR', error})
        })
    }
}
